import React from "react";
import Layout from "../components/Layout";
import Button from "../components/common/Button";

const ComponentsPage = () => {
  return (
    <Layout>
      <Button variant="blue" href="https://runemadsen.com">
        External Link Button
      </Button>
      <Button variant="blue" href="/page">
        Internal Link Button
      </Button>
      <Button variant="blue" onClick={() => console.log("clicked")}>
        Button (onClick)
      </Button>
      <br />
      <br />
      <Button variant="blue" size="small" href="/page">
        Small Button
      </Button>
      <Button variant="blue" href="/page">
        Normal Button
      </Button>
      <Button variant="blue" size="large" href="/page">
        Large Button
      </Button>
    </Layout>
  );
};

export default ComponentsPage;
